import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../../../../../../common/components/input/input';
import { AttForm, FormType } from '../../../../../../redux/FormSlice';
import { useFiscalSeriemask } from '../../../../utils/masks/use-fiscal-serie-mask';
import Session from '../session';

function FiscalSession(props: { ErrorMessageInputs: FormType }) {
  const dispatch = useDispatch();
  const form = useSelector((state: { form: { value: FormType } }) => state.form);

  function handleNFeSerie(value: string) {
    dispatch(AttForm({ key: 'nfeSerie', value: useFiscalSeriemask(value) }));
    if (value != '') {
      dispatch(AttForm({ key: 'nfeLastNumber', value: '1' }));
    }
  }

  function handleNFCeSerie(value: string) {
    dispatch(AttForm({ key: 'nfceSerie', value: useFiscalSeriemask(value) }));
    if (value != '') {
      dispatch(AttForm({ key: 'nfceLastNumber', value: '1' }));
    }
  }

  return (
    <Session Title='Configurações Fiscais'>
      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Série NF-e'
        type='text'
        value={form.value.nfeSerie}
        onChange={(e) => handleNFeSerie(e.target.value)}
        ErrorMessage={props.ErrorMessageInputs.nfeSerie}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Último número NF-e'
        type='number'
        value={form.value.nfeLastNumber}
        onChange={(e) => dispatch(AttForm({ key: 'nfeLastNumber', value: e.target.value }))}
        ErrorMessage={props.ErrorMessageInputs.nfeLastNumber}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        value={form.value.nfceSerie}
        placeholder='Série NFC-e'
        type='text'
        ErrorMessage={props.ErrorMessageInputs.nfceSerie}
        onChange={(e) => handleNFCeSerie(e.target.value)}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='Último número NFC-e'
        type='number'
        value={form.value.nfceLastNumber}
        ErrorMessage={props.ErrorMessageInputs.nfceLastNumber}
        onChange={(e) => dispatch(AttForm({ key: 'nfceLastNumber', value: e.target.value }))}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='CSC'
        type='text'
        value={form.value.nfceCSC}
        ErrorMessage={props.ErrorMessageInputs.nfceCSC}
        onChange={(e) => dispatch(AttForm({ key: 'nfceCSC', value: e.target.value }))}
      ></Input>

      <Input
        InputSize='medium'
        floatingLabel={true}
        placeholder='ID CSC'
        type='text'
        value={form.value.nfceIdCSC}
        ErrorMessage={props.ErrorMessageInputs.nfceIdCSC}
        onChange={(e) => dispatch(AttForm({ key: 'nfceIdCSC', value: e.target.value }))}
      ></Input>
    </Session>
  );
}

export default FiscalSession;
